.event-page{
    background-image: url('../image/event_back.png');
    background-position: center 21.042vw;
    background-repeat: no-repeat;
    background-size: contain;
    &--map{
        display: flex;
        border-bottom: 5px solid var(--green-color);
        iframe{
            width: 100%;
            height: 34.896vw;
            border: 0;
            @include mobile {
                height: 320px;
            }
        }
    }
    &--current{
        margin-bottom: 3.750vw;
        @include mobile{
            margin-bottom: 24px;
        }
        &_card{
            padding: 3.021vw 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include mobile{
                padding: 24px 0;
            }
            &Detail{
                width: calc(100% - 33.333vw);
                margin: auto 0;
                @include mobile {
                    width: 100%;
                }
                &Title{
                    color: var(--pink-color);
                    font-weight: bold;
                    font-family: var(--second-font);
                    font-size: 3.333vw;
                    margin-bottom: 1.250vw;
                    margin-left: -4px;
                    @include mobile {
                        font-size: 24px;
                        margin-left: 0;
                        margin-bottom: 14px;
                    }
                }
                &Desc{
                    font-size: 16px;
                    margin-bottom: 24px;
                    @include mobile{
                        margin-bottom: 16px;
                    }
                }
                &Social{
                    display: flex;
                    gap: 14px;
                    @include mobile{
                        margin-bottom: 14px;
                    }
                }
            }
            &Image{
                width: 30.208vw;
                @include mobile {
                    width: 100%;
                }
                img{
                    width: 100%;
                }
            }
        }
        &_date{
            position: relative;
            height: 18.229vw;
            @include mobile{
                padding: 32px 0;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            &Title{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                display: flex;
                left: 20.833vw;
                img{
                    width: 12.135vw;
                    height: 13.854vw;
                }
                @include mobile{
                    display: none;
                }
            }
            &Detail{
                position: absolute;
                z-index: 2;
                top: 0;
                bottom: 0;
                right: 0;
                width: 60.313vw;
                background-color: rgba(0, 0, 0, .3);
                display: flex;
                @include mobile{
                    position: relative;
                    top: auto;
                    left: auto;
                    bottom: auto;
                    right: auto;
                    width: 100%;
                    justify-content: center;
                }
                .countdown{
                    margin: auto auto auto 64px;
                    font-size: 6.250vw;
                    color: #fb71a2;
                    display: flex;
                    font-family: var(--oswald-font);
                    @include mobile{
                        font-size: 48px;
                        margin: auto 0;
                    }
                    &-date{
                        margin-right: 2.500vw;
                        @include mobile {
                            margin-right: 18px;
                        }
                    }
                    &-timer{

                    }
                }
            }
        }
    }
    &--more{
        padding-bottom: 3.854vw;
        @include mobile {
            padding-bottom: 32px;
        }
        &_title{
            color: var(--pink-color);
            font-weight: bold;
            font-family: var(--second-font);
            font-size: 3.333vw;
            margin-bottom: 1.250vw;
            margin-left: -4px;
            @include mobile{
                font-size: 24px;
                margin-bottom: 12px;
                margin-left: 0;
            }
        }
        &_list{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 80px;
            margin-bottom: 64px;
            @include mobile{
                gap: 24px;
                margin-bottom: 24px;
                grid-template-columns: repeat(1, 1fr);
            }
            &Item{
                position: relative;
                display: flex;
                img{
                    width: 100%;
                }
                a{
                    position: absolute;
                    z-index: 2;
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 164px;
                    height: 43px;
                    background-color: var(--white-color);
                    display: flex;
                    border-radius: 4px;
                    span{
                        display: block;
                        margin: auto;
                        color: var(--pink-color);
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
        &_pagination{
            display: flex;
            justify-content: center;
            &Item{
                position: relative;
                button{
                    width: 40px;
                    height: 40px;
                    background-color: #ffd231;
                    display: flex;
                    border-radius: 500px;
                    box-shadow: 0 0 14px rgba(0, 0, 0, .3);
                    border: none;
                    padding: 0;
                    margin: 0;
                    .arrow-top{
                        margin: 12px auto auto;
                        border: solid var(--white-color);
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 5px;  
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
}