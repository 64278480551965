.footer{
    background-color: var(--dark-color);
    border-top: 5px solid var(--pink-color);
    padding-bottom: 60px;
    background-image: url(../image/footer_layer.png);
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    @include mobile{
        border-top: 2px solid var(--pink-color);
        padding-bottom: 32px;
    }
    &-logo{
        display: flex;
        margin-top: 94px;
        margin-bottom: 48px;
        @include mobile{
            margin-top: 32px;
            margin-bottom: 24px;
        }
        a{
            margin: auto;
            svg{
                @include mobile{
                    width: 62px;
                    height: auto;
                }
            }
        }
    }
    &-menu{
        margin-bottom: 52px;
        @include mobile{
            margin-bottom: 32px;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li{
                margin-right: 70px;
                @include mobile{
                    margin-right: 24px;
                }
                &:last-child{
                    margin-right: 0;
                }
                a{
                    color: var(--white-color);
                    font-size: 18px;
                    font-weight: bold;
                    font-family: var(--main-font);
                    padding-bottom: 8px;
                    position: relative;
                    @include mobile{
                        font-size: 13px;
                    }
                }
            }
        }
    }
    &-contact{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &_list{
            margin-right: 42px;
            @include mobile{
                margin-right: 0;
                width: 100%;
                margin-bottom: 16px;
            }
            &:last-child{
                margin-right: 0;
                @include mobile{
                    margin-bottom: 0;
                }
            }
            &Item{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 16px;
                @include mobile{
                    justify-content: center;
                    text-align: center;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                div{
                    margin-right: 12px;
                }
                span{
                    color: var(--white-color);
                    font-size: 16px;
                    font-style: var(--main-font);
                    @include mobile{
                        font-size: 13px;
                    }
                }
            }
        }
    }
    &-social{
        border-top: 2px solid var(--gray-color);
        margin-top: 80px;
        padding-top: 40px;
        @include mobile{
            margin-top: 40px;
            padding-top: 28px;
        }
        &_logo{
            background-image: url(../image/social-icons.png);
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: cover;
            @include mobile{
                width: 32px;
                height: 32px;
            }
            &.tw{
                background-position: 0 0px;
            }
            &.ytb{
                background-position: -52px 0px;
                @include mobile{
                    background-position: -42px 0px;
                }
            }
            &.inst{
                background-position: -105px 0px;
                @include mobile{
                    background-position: -84px 0px;
                }
            }
            &.fb{
                background-position: -159px 0px;
                @include mobile{
                    background-position: -127px 0px;
                }
            }
            &.in{
                background-position: -209px 0px;
                @include mobile{
                    background-position: -167px 0px;
                }
            }
        }
        ul{
            display: flex;
            justify-content: center;
            li{
                margin-right: 24px;
                @include mobile{
                    margin-right: 16px;
                }
                &:last-child{
                    margin-right: 0;
                }
                a{
                    display: flex;
                }
            }
        }
    }
}