.comic-read{
    padding: 116px 0;
    @include tablet {
        padding: 48px 0;
    }
    @include mobile {
        padding: 24px 0;
    }
    &_card{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 48px;
        @include mobile{
            margin-bottom: 14px;
        }
        &Cover{
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.22);
            display: flex;
            width: 400px;
            margin: auto 48px auto 0;
            @include mobile {
                width: 100%;
                margin: auto 0 24px;
                display: none;
            }
            img{
                width: 100%;
            }
        }
        &Body{
            width: calc(100% - 448px);
            @include mobile{
                width: 100%;
            }
            &Title{
                font-family: var(--second-family);
                font-size: 64px;
                font-weight: bold;
                color: #fa4b8a;
                @include tablet {
                    font-size: 48px;
                }
                @include mobile {
                    font-size: 6.400vw;
                    text-align: center;
                    margin-bottom: 14px;
                }
            }
            &Description{
                font-size: 16px;
            }
        }
    }
    &_gallery{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &Item{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
}