.main-content{
    margin-top: 162px;
    @include mobile{
        margin-top: 72px;
    }
    @include tablet{
        margin-top: 72px;
    }
}
.home{
    @import './_usage';
    &-bottom{
        position: relative;
        padding-top: 4.688vw;
        overflow: hidden;
        .mobile-content{
            display: none;
            @include mobile{
                display: block;
            }
        }
        &_section{
            position: relative;
            z-index: 2;
            margin-top: 0%;
            pointer-events: none;
            .bottom-back{
                display: flex;
                position: relative;
                width: 100%;
                min-height: 72.604vw;
                pointer-events: none;
                @include mobile{
                    min-height: 196vw;
                }
                @include tablet{
                    min-height: 84.604vw;
                }
                &_img{
                    position: absolute;
                    top: -40.625vw;
                    left: 6.667vw;
                    width: 46.823vw;
                    @include mobile{
                        display: none;
                    }
                }
                .loop_tailagch{
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    width: 46.823vw;
                    left: 6.7%;
                    overflow: hidden;
                    @include mobile{
                        display: none;
                    }
                    img{
                        position: absolute;
                        left: 6.563vw;
                        right: 0;
                        bottom: -2.917vw;
                        width: 76.510vw;
                        @include tablet{
                            bottom: 2.083vw;
                        }
                    }
                }
            }
            .pink_box{
                position: absolute;
                left: 0;
                bottom: 34.375vw;
                width: 7.292vw;
                height: 2.708vw;
                background-color: var(--pink-color);
                @include mobile{
                    display: none;
                }
            }
            .pattern{
                position: absolute;
                left: 0;
                top: -8.229vw;
                width: 10.885vw;
                @include mobile{
                    display: none;
                }
            }
        }
        &_detail{
            position: absolute;
            bottom: 80.938vw;
            left: 13.229vw;
            z-index: 3;
            pointer-events: none;
            @include mobile{
                bottom: 200vw;
                left: 50%;
                transform: translateX(-50%);
                width: 76.533vw;
            }
            @include tablet{
                bottom: 89.938vw;
            }
            .layer1, .layer2{
                position: absolute;
            }
            .layer1{
                left: 0;
                top: 0;
                width: 76.510vw;
            }
            .layer2{
                left: 0.260vw;
                top: 0.208vw;
                width: 75.990vw;
            }
        }
        &_sekhuun{
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            pointer-events: none;
            img{
                width: 21.979vw;
            }
        }
        &_mainimage{
            position: absolute;
            z-index: 4;
            width: 35.417vw;
            height: 19.948vw;
            background-color: #fff;
            bottom: 34.583vw;
            left: 11.875vw;
            @include mobile{
                width: 85.333vw;
                height: 49.067vw;
                bottom: 130vw;
                left: 50%;
                transform: translateX(-50%);
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            iframe{
                width: 100%;
                height: 100%;
            }
        }
        &_text{
            position: absolute;
            right: 5.896vw;
            bottom: 37.667vw;
            z-index: 4;
            width: 34.375vw;
            @include mobile{
                right: auto;
                bottom: 15.4vw;
                width: 80%;
                left: 50%;
                transform: translateX(-50%);
            }
            @include tablet{
                right: 2.896vw;
                bottom: 29.297vw;
                width: 40vw;
            }
            @include small_desktop{
             bottom: 30vw;       
            }
            h4{
                font-size: 1.250vw;
                margin-bottom: 1.667vw;
                font-family: var(--second-font);
                color: var(--pink-color);
                span{
                    color: var(--yellow-color);
                }
                @include mobile{
                    font-size: 4.800vw;
                    text-align: center;
                }
                @include tablet{
                    font-size: 2.344vw;
                }
            }
            p{
                font-size: 14px; 
                @include mobile{
                    font-size: 3.200vw;
                    text-align: center;
                }
                @include tablet{
                    font-size: 13px;
                }
                span{
                    font-family: var(--second-font);
                    color: var(--pink-color);
                    font-weight: 600;
                }
            }
        }
    }
    &-plus{
        @include desktop{
            position: relative;
            max-width: 44.792vw;
            width: 100%;
            margin: 0 auto 0 11.875vw;
            padding: 0 24px;
        }
        @include tablet{
            display: none;
        }
    }
    &-product{
        @include desktop{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 5.208vw;
            z-index: 2;
        }
        @include mobile{
            padding: 0 24px;
        }
        &_tt{
            font-weight: 600;
            font-family: var(--second-font);
            color: #4dd89a;
            @include desktop{
                color: #fff;
                position: relative;
                width: max-content;
                &::after{
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    width: 30%;
                    height: 2px;
                    background-color: #fff;
                }
            }
            @include mobile{
                font-size: 18px;
                padding-bottom: 8px;
                margin-bottom: 24px;
                border-bottom: 1px solid #4dd89a;
            }
        }
        .homeSwiper{
            @include mobile{
                padding: 0 24px 42px;
            }
            .swiper-slide{
                .image{
                    width: 100%;
                    padding: 24px;
                    background-color: #FFD336;
                    margin-bottom: 12px;
                    border-radius: 4px;
                    img{
                        width: 100%;
                    }
                    .title{
                        color: #000;
                        font-family: var(--second-font);
                        text-align: center;
                        font-weight: 500;
                        margin-top: 14px;
                        @include mobile{
                            font-size: 16px;
                        }
                    }
                }
                .link-btn{
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-family: var(--second-font);
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0 auto;
                    background-color: #fff;
                    border: 1px solid var(--pink-color);
                    color: var(--pink-color);
                    width: 100%;
                    border-radius: 4px;
                    padding: 8px 24px;
                }
            }
            .swiper-pagination{
                .swiper-pagination-bullet{
                    background: #50e6a3;
                    width: 6px;
                    height: 6px;
                    opacity: 1;
                    transition: 0.3s ease;
                    &.swiper-pagination-bullet-active{
                        width: 40px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
    &-news{
        position: relative;
        @include desktop{
            display: none;
        }
        &::after{
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            bottom: 0;
            height: 88%;
            background-color: var(--yellow-color);
        }
        @include mobile{
            padding: 0 24px;
        }
        &_tt{
            font-weight: 600;
            font-family: var(--second-font);
            color: #4dd89a;
            @include mobile{
                font-size: 18px;
                padding-bottom: 8px;
                margin-bottom: 24px;
                border-bottom: 1px solid #4dd89a;
            }
        }
        .newsSwiper{
            @include mobile{
                padding: 0 0 42px;
            }
            .swiper-slide{
                .image{
                    width: 100%;
                    img{
                        width: 100%;
                        border-radius: 4px;
                    }
                    .title{
                        color: #000;
                        font-family: var(--second-font);
                        text-align: center;
                        font-weight: 500;
                        margin-top: 14px;
                        margin-bottom: 14px;
                        @include mobile{
                            font-size: 13px;
                        }
                    }
                }
                .link-btn{
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-family: var(--second-font);
                    text-align: center;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin: 0 auto;
                    background-color: #fff;
                    color: var(--pink-color);
                    width: 100%;
                    border-radius: 4px;
                    font-size: 12px;
                    padding: 8px 24px;
                }
            }
            .swiper-pagination{
                .swiper-pagination-bullet{
                    background: #50e6a3;
                    width: 6px;
                    height: 6px;
                    opacity: 1;
                    transition: 0.3s ease;
                    &.swiper-pagination-bullet-active{
                        width: 40px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}