@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap');

:root {
    --dark-color: rgba(0, 0, 0, 1);
    --white-color: rgba(255, 255, 255, 1);
    --pink-color: rgba(250, 75, 138, 1);
    --green-color: rgba(80, 230, 163, 1);
    --gray-color: rgba(137, 137, 137, 1);
    --yellow-color: rgba(255, 211, 50, 1);
    --main-font: 'Roboto', sans-serif;
    --second-font: 'Montserrat', sans-serif;
    --oswald-font: 'Oswald', sans-serif;
}

$mobile-width: 320px;
$tablet-width: 768px;
$smalldesktop-width: 1366px;
$desktop-width: 1024px;

@mixin mobile {
    @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
      @content;
    }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin small_desktop {
    @media (min-width: #{$desktop-width + 1px}) and (max-width: #{$smalldesktop-width}) {
      @content;
    }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body{
    margin: 0;
    padding: 0;
    cursor: none;
}

body{
    overflow-x: hidden;
    font-family: var(--main-font);
    color: var(--dark-color);
}
canvas, img, video{
    max-width: none;
}
a, a:hover{
    text-decoration: none;
}

ul{
    &.list-none{
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.tc-container{
    max-width: 1408px;
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
    &.flex{
        display: flex;
        flex-wrap: wrap;
    }
}

.scroll-to-top{
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99;
    button{
        width: 40px;
        height: 40px;
        background-color: #ffd231;
        display: flex;
        border-radius: 500px;
        box-shadow: 0 0 14px rgba(255, 255, 255, .6);
        border: none;
        padding: 0;
        margin: 0;
        .arrow-top{
            margin: 16px auto auto;
            border: solid var(--white-color);
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;  
            transform: rotate(-135deg);
        }
    }
}

.coming-soon{
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding: 24px 24px 32px;
    img{
        width: 100%;
        margin-bottom: 24px;
    }
    p{
        font-size: 48px;
        font-weight: 700;
        text-align: center;
        font-style: italic;
    }
}

.social-share{
    background-image: url(../image/social-icons.png);
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    filter: invert(1);
    @include mobile{
        width: 32px;
        height: 32px;
    }
    &.tw{
        background-position: 0 0px;
    }
    &.ytb{
        background-position: -52px 0px;
        @include mobile{
            background-position: -42px 0px;
        }
    }
    &.inst{
        background-position: -105px 0px;
        @include mobile{
            background-position: -84px 0px;
        }
    }
    &.fb{
        background-position: -159px 0px;
        @include mobile{
            background-position: -127px 0px;
        }
    }
    &.in{
        background-position: -209px 0px;
        @include mobile{
            background-position: -167px 0px;
        }
    }
}

.lazyload, .lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}

.loading-page{
 position: fixed;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 z-index: 998;
 background-color: #000;
 display: flex;
 &>div{
     margin: auto;
     animation: spin 4s ease-in-out infinite;
 }
 @keyframes spin {
    0% {
        filter: drop-shadow(0px 0px 12px rgba(83, 230, 160, 1));
    }
    20% {
        filter: drop-shadow(0px 0px 12px rgba(255, 211, 50, 1));
    }
    40% {
        filter: drop-shadow(0px 0px 12px rgba(250, 75, 138, 1));
    }
    60% {
        filter: drop-shadow(0px 0px 12px rgba(83, 230, 160, 1));
    }
    80% {
        filter: drop-shadow(0px 0px 12px rgba(255, 211, 50, 1));
    }
    100% {
        filter: drop-shadow(0px 0px 12px rgba(250, 75, 138, 1));
    }
  }  
}