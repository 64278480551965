.usage{
    background-color: var(--yellow-color);
    position: relative;
    z-index: 3;
    &-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 4.271vw 0 2.813vw;
        @include mobile{
            justify-content: center;
        }
        &_item{
            position: relative;
            @include mobile{
                width: 90%;
                margin-bottom: 8.533vw;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            @include tablet{
                padding-left: 60px;
            }
            .detail{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @include mobile{
                    left: 0;
                }   
                @include tablet{
                    left: 0;
                }
                .number{
                    font-size: 5.417vw;
                    font-weight: bold;
                    @include mobile{
                        font-size: 16vw;
                    }   
                    @include tablet{
                        font-size: 48px;
                    }
                }
                .desc{
                    font-size: 0.938vw;
                    font-weight: 600;
                    @include mobile{
                        font-size: 3.733vw;
                    }   
                    @include tablet{
                        font-size: 14px;
                    }
                }
            }
            .img{
                width: 20.052vw;
                height: 28.906vw;
                @include mobile{
                    width: 41.067vw;
                    height: 60.267vw;
                    margin: 0 auto;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}