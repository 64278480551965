.cover{
    width: 100%;
    height: 34.896vw;
    background-color: var(--yellow-color);
    position: relative;
    &-detail, &-image, &-left, &-right{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        .absolute{
            position: absolute;
        }
    }
    &-detail{
        z-index: 2;
        overflow: hidden;
        &_1{
            height: 100%;
            img{
                width: 52.240vw;
            }
        }
        &_2{
            left: 37.917vw;
            bottom: -1.198vw;
            z-index: 2;
            img{
                width: 26.198vw;
                height: 41.406vw;
            }
        }
        &_3{
            left: 34.479vw;
            bottom: -4.479vw;
            z-index: 3;
            img{
                width: 20.833vw;
                height: 33.333vw;
            }
        }
    }
    &-image{
        z-index: 3;
        &_1{
            left: 38.958vw;
            bottom: -0.677vw;
            z-index: 1;
            img{    
                width: 23.438vw;
                height: 20.573vw;
            }
        }
        &_2{
            left: 45.833vw;
            bottom: -1.563vw;
            z-index: 2;
            img{
                width: 9.375vw;
                height: 28.698vw;
            }
        }
        &_3{
            left: 37.813vw;
            bottom: 13.490vw;
            z-index: 3;
            img{
                width: 6.250vw;
                height: 4.688vw;
            }
        }
        &_4{
            right: 35vw;
            bottom: 13.958vw;
            z-index: 3;
            img{
                width: 6.250vw;
                height: 3.646vw;
            }
        }
    }
    &-left{
        z-index: 2;
        &_1{
            left: 33.021vw;
            bottom: 5.313vw;
            img{
                width: 7.760vw;
                height: 6.354vw;
            }
        }
        &_2{
            left: 21.458vw;
            bottom: 13.021vw;
            img{
                width: 20.833vw;
                height: 6.250vw;
            }
        }
    }
    &-right{
        z-index: 2;
        overflow: hidden;
        &_1{
            right: 26.146vw;
            bottom: 7.083vw;
            img{
                width: 16.667vw;
                height: 16.667vw;
            }
        }
        &_2{
            right: 34.115vw;
            top: 7.344vw;
            img{    
                width: 8.854vw;
                height: 8.698vw;
            }
        }
        &_3{
            right: 14.167vw;
            bottom: -0.625vw;
            img{
                width: 13.021vw;
                height: 28.594vw;
            }
        }
        &_4{
            right: 22.135vw;
            bottom: 13.646vw;
            img{
                width: 15.208vw;
                height: 4.688vw;
            }
        }
    }
    &-link{
        left: 22.708vw;
        bottom: 2.917vw;
        position: absolute;
        z-index: 5;
        a{
            width: 8.542vw;
            height: 2.292vw;
            display: flex;
            background-color: var(--white-color);
            border-radius: 4px;
            span{
                display: block;
                font-size: 0.885vw;
                font-weight: 500;
                color: var(--pink-color);
                margin: auto;
            }
        }
    }
}