.games-page{
    padding: 72px 0;
    @include tablet{
        padding: 24px 0;
    }
    @include mobile{
        padding: 24px 0;
    }
    .games-list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        @include mobile{
            grid-template-columns: 1fr;
        }
        &_item{
            box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
            padding: 24px;
            display: grid;
            gap: 24px;
            border-radius: 8px;
            @include tablet{
                padding: 14px;
                gap: 14px;
            }
            @include mobile{
                padding: 14px;
                gap: 14px;
            }
            &.grid-1{
                grid-template-columns: 1fr;
            }
            &.grid-2{
                grid-template-columns: 1fr 1fr;
                @include mobile{
                    grid-template-columns: 1fr;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            &Media{
                border-radius: 4px;
                overflow: hidden;
            }
            &Body{
                margin: auto 0;
                &Title{
                    font-size: 24px;
                    font-weight: 600;
                    font-family: var(--second-font);
                    color: var(--pink-color);
                    padding-bottom: 4px;
                    margin-bottom: 14px;
                    position: relative;
                    z-index: 2;
                    @include tablet{
                        font-size: 18px;
                    }
                    @include mobile{
                        font-size: 18px;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        left: 0;
                        bottom: 0;
                        width: 20%;
                        height: 2px;
                        background-color: var(--yellow-color);
                    }
                }
                &Text{
                    font-size: 16px;
                    color: #000;
                    @include tablet{
                        font-size: 14px;
                    }
                    @include mobile{
                        font-size: 14px;
                    }
                    * + p{
                        margin-top: 0;
                    }
                    p {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}