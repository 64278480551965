.header{
    display: flex;
    background-color: var(--dark-color);
    border-bottom: 5px solid var(--pink-color);
    height: 162px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    @include mobile{
        height: 72px;
        border-bottom: 2px solid var(--pink-color);
        &.open{
            position: fixed;
        }
    }
    @include tablet{
        height: 72px;
        border-bottom: 2px solid var(--pink-color);
    }
    .tc-container{
        margin: auto;
        @include mobile{
            justify-content: space-between;
        }
        @include tablet{
            justify-content: space-between;
        }
    }
    &-logo{
        width: 116px;
        @include mobile{
            width: 62px;
        }
        @include tablet{
            width: 60px;
        }
        a{
            display: flex;
            svg{
                width: 100%;
                height: auto;
            }
        }
    }
    &-menu{
        margin: auto;
        padding-right: 116px;
        @include mobile{
            display: none;
            padding-right: 0;
        }
        @include tablet{
            display: none;
            padding-right: 0;
        }
        &_mobile{
            margin: auto 0;
            display: block;
            @include desktop{
                display: none;
            }
            .burger-menu {
                margin: 0 auto;
                width: 32px;
                height: 22px;
                display: block;
                transition: all 0.3s;
                cursor: pointer;
                .burger-bar {
                    transition: all 0.3s;
                    height: 2px;
                    width: 100%;
                    display: block;
                    background-color: white;
                    &:nth-of-type(2) {
                        margin: 8px 0;
                    }
                }
                &--closed {
                    transition-delay: 0.3s;
                    .burger-bar {
                        float: right;
                        &:nth-of-type(2) {
                            width: 75%;
                            transition-property: margin, height, width;
                            transition-delay: 0.3s, 0.3s, 0s;
                        }
                        &:nth-of-type(3) {
                            width: 50%;
                        }
                    }
                    &:hover .burger-bar:nth-of-type(2) {
                        width: 100%;
                    }
                    &:hover .burger-bar:nth-of-type(3) {
                        width: 100%;
                    }
                }
                &--opened {
                    padding-top: 12px;
                    .burger-bar:nth-of-type(1) {
                        transform: rotate(45deg);
                        transition-delay: 0.3s;
                        height: 2px;
                    }
                    .burger-bar:nth-of-type(2) {
                        opacity: 0;
                        height: 0;
                        margin: -3px;
                    }
                    .burger-bar:nth-of-type(3) {
                        transform: rotate(-45deg);
                        transition-delay: 0.3s;
                        height: 2px;
                    }
                }
            }
            &Back{
                background: rgba(0, 0, 0, 0.7);
                position: fixed;
                left: auto;
                right: 0;
                top: 72px;
                bottom: 0;
                width: 0;
                z-index: 999;
                transition: 0.3s ease-in-out;
                &.open{
                    left: auto;
                    width: 100%;
                }
            }
            &Item{
                position: fixed;
                left: auto;
                right: 0;
                top: 72px;
                bottom: 0;
                display: flex;
                z-index: 1000;
                width: 270px;
                transform: translateX(270px);
                transition: 0.3s ease-in-out;
                background-color: var(--dark-color);
                padding: 64px 24px 64px 32px;
                ul{
                    li{
                        margin-bottom: 24px;
                        transition: 0.5s ease-in-out;
                        transition-delay: 0s;
                        transform: translateX(30px);
                        opacity: 0;
                        a{  
                            font-size: 24px;
                            color: var(--white-color);
                        }
                    }
                }
                &.open{
                    transform: translateX(0);
                    ul{
                        li{
                            transform: translateX(0);
                            opacity: 1;
                            @for $i from 1 through 10 {
                                &:nth-child(#{$i}n) {
                                    transition-delay: #{$i * 0.2}s;
                                }
                            }
                        }
                    }
                }
            }
        }
        ul{
            display: flex;
            li{
                margin-right: 60px;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    color: var(--white-color);
                    font-size: 18px;
                    font-weight: bold;
                    font-family: var(--main-font);
                    padding-bottom: 8px;
                    position: relative;
                    &.active{
                        &::after{
                            width: 100%;
                        }
                    }
                    &::after{
                        content: "";
                        width: 0;
                        height: 3px;
                        background-color: var(--green-color);
                        position: absolute;
                        left: auto;
                        right: 0;
                        bottom: 0;
                        transition: 0.3s ease;
                    }
                    &:hover{
                        &::after{
                            width: 100%;
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
        }
    }
}